import React, { useState, useEffect } from 'react'
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import data from 'src/helpers/merchantData.js'
import Icon from 'src/components/Icon'
import Swiper from 'swiper'
import 'swiper/swiper.scss'
import './merchant.scss'
import { useGetBusinessLinkByEnv } from '../helpers/hooks'

const {
  header,
  introduce,
  product,
  advantage,
  scheme,
  client
} = data


const Merchant = () => {
  const [advantageIndex, setAdvantageIndex] = useState(0)
  const [schemeIndex, setSchemeIndex] = useState(0)
  const [clientIndex, setClientIndex] = useState(0)
  const businessLink = useGetBusinessLinkByEnv()

  useEffect(() => {
    const { clientWidth } = document.documentElement
    // 初始化解决方案轮播
    let mySwiper = new Swiper('.scheme-swiper-container', {
      slidesPerView: clientWidth > 750 ? 7 : 5,
      centeredSlides: true,
      loop: true,
      slideToClickedSlide: true,
      threshold: 10,
      navigation: {
        nextEl: '.scheme-swiper-button-next',
        prevEl: '.scheme-swiper-button-prev',
      },
      on: {
        slideChange: function () {
          setSchemeIndex(this.realIndex)
        },
      },
    })
  }, [])

  useEffect(() => {
    const { clientWidth } = document.documentElement
    // 初始化部分客户轮播图
    let mySwiper1 = new Swiper('.client-swiper-container', {
      slidesPerView: clientWidth > 750 ? 5 : 3,
      spaceBetween: clientWidth > 750 ? 30 : 15,
      loop: true,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: '.client-swiper-button-next',
        prevEl: '.client-swiper-button-prev',
      },
    })
  }, [clientIndex])

  const toggleAdvantage = index => setAdvantageIndex(index)
  const toggleClient = index => setClientIndex(index)

  const Contact = props => (
    <a className="contact-us-btn" style={props.style}  href={businessLink} target="_blank">联系我们
    </a>
  )

  return (
    <Layout>
      <SEO title="商家合作" />
      <div className="merchant">
        <div className="merchant-head">
          <div className="header-img show-bg">
            <video
              className="video-style"
              autoPlay
              loop
              muted
            >
              <source src='https://fe.imdada.cn/samoyed/assets/merchant1.mp4'></source>
            </video>
          </div>
          <div className="header-img-s show-sm bg">
            <video
              className="video-style"
              autoPlay
              loop
              muted
            >
              <source src='https://fe.imdada.cn/samyoed/assets/merchant-s.mp4'></source>
            </video>
          </div>
          <div className="header-content">
            <div className="head-title">全心服务 即时送达</div>
            <div className="subhead">提供定制化的混合运力模式和服务体系</div>
            <div className="flex flex-Aconter">
              {header.list.map((item, index) => (
                <div className="icon-item flex flex-col flex-Aconter" key={index}>
                  <img className="head-icon" src={item.icon} alt="" />
                  <div className="icon-title">{item.title}</div>
                </div>
              ))}
            </div>
            <Contact />
          </div>
        </div>

        <div className="merchant-introduce flex-col flex-Aconter">
          <div className="introduce-title">达达秒送即时配服务</div>
          <div className="introduce-content flex flex-Aconter">
            <img className="colon" src={introduce.img.leftImg}></img>
            <div className="text">{introduce.content}</div>
            <img className="colon" src={introduce.img.rightImg}></img>
          </div>
          <div className="introduce-list flex-wrap flex-Jbetween">
            {
              introduce.list.map((item, index) => (
                <div className="list-item flex-col flex-Jconter flex-Aconter" key={index}>
                  <div className="item-data">
                    <span className="data-num">{item.data}</span>
                    <span>{item.unit}</span>
                  </div>
                  <div className="item-info">{item.explain}</div>
                </div>
              ))
            }
          </div>
        </div>

        <div className="merchant-product">
          <div className="merchant-product-bg bg show-bg" style={{ backgroundImage: `url(${product.bgImg})` }}></div>
          <div className="merchant-product-bg bg show-sm" style={{ backgroundImage: `url(${product.bgImgS})` }}></div>
          <div className="product-content">
            <div className="product-title">服务产品</div>
            <div className="product-info">
              <div className="product-box">
                <div className="product-list flex-wrap flex-Jbetween">
                  {
                    product.list.map((item, index) => (
                      <div className="product-item" key={index}>
                        <div className="flex flex-Aconter">
                          <img className="product-item-icon" src={item.icon} />
                          <span className="product-item-title">{item.title}</span>
                        </div>
                        <div className="product-item-con">
                          {item.text}
                        </div>
                      </div>
                    ))
                  }
                </div>
                <div className="product-contact-btn show-bg">
                  <Contact />
                </div>
              </div>
            </div>
            <div className="product-contact-btn-sm show-sm">
              <Contact style={{ width: '12.5rem' }} />
            </div>
          </div>
        </div>

        <div className="merchant-advantage">
          <div className="advantage-title">服务优势</div>
          <div className="advantage-group">
            <div className="group-top flex flex-Jconter">
              {
                advantage.group.map((item, index) => (
                  <div className={`group-title ${advantageIndex === index ? "title-active" : null}`} key={index} onClick={() => toggleAdvantage(index)}>
                    {item.title}
                    <i className="title-active-line"></i>
                  </div>
                ))
              }
            </div>
            <div className="group-bottom flex flex-Jbetween">
              {
                advantage.group[advantageIndex].list.map((item, index) => (
                  <div className="group-bottom-item" key={index}>
                    <img className="group-item-icon" src={item.icon} alt="" />
                    <div className="group-item-title">{item.title}</div>
                    <div className="group-item-text">{item.text}</div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>

        <div className="merchant-scheme">
          {
            scheme.list.map((item, index) => (
              <div key={index}>
                <div className="scheme-bg bg show-bg" style={{ backgroundImage: `url(${item.bgImg})`, opacity: index === schemeIndex ? 1 : 0 }}></div>
                <div className="scheme-bg-s bg show-sm" style={{ backgroundImage: `url(${item.bgImgS})`, opacity: index === schemeIndex ? 1 : 0 }}></div>
              </div>
            ))
          }
          <div className="scheme-content">
            <div className="scheme-title">业务场景解决方案</div>
            <div className="scheme-swiper flex flex-Aconter">
              <div className='scheme-swiper-button-prev flex-center'>
                <Icon icon="angle-left" className="scheme-swiper-btn-icon" />
              </div>
              <div className='scheme-swiper-container swiper-container'>
                <div className='swiper-wrapper'>
                  {scheme.list.map((item, index) => (
                    <div className='swiper-slide scheme-swiper-slide flex-col-center' key={index}>
                      <div className='swiper-iocn-wrapper flex-center'>
                        <Icon icon={item.icon} className="swiper-item-icon" />
                      </div>
                      <span className="swiper-item-ttile">{item.title}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className='scheme-swiper-button-next flex-center'>
                <Icon icon="angle-right" className="scheme-swiper-btn-icon" />
              </div>
            </div>
            <div className="scheme-info-card flex">
              <img className="scheme-info-left show-bg" src={scheme.list[schemeIndex].contentImg} />
              <div className="scheme-info-right">
                <div className="scheme-detail flex">
                  <div className="scheme-detail-item">
                    <div className="scheme-detail-title">行业特性</div>
                    {
                      scheme.list[schemeIndex].feature.map((item, index) => (
                        <div className="scheme-detail-list" key={index}>{item}</div>
                      ))
                    }
                  </div>
                  <div className="scheme-detail-item">
                    <div className="scheme-detail-title">行业解决方案</div>
                    {
                      scheme.list[schemeIndex].project.map((item, index) => (
                        <div className="scheme-detail-list" key={index}>{item}</div>
                      ))
                    }
                  </div>
                </div>
                {
                  scheme.list[schemeIndex].client.length && <div className="scheme-client">
                    <div className="scheme-client-title">代表客户</div>
                    <div className="scheme-client-li flex">
                      {
                        scheme.list[schemeIndex].client.map((item, index) => (
                          <img className="scheme-client-item" src={item} key={index} />
                        ))
                      }
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>

        <div className="merchant-client bg" style={{ backgroundImage: `url(${client.bgImg})` }}>
          <div className="client-content">
            <div className="client-title">部分合作客户</div>
            <div className="client-list-wrapper flex-col flex-Aconter">
              <div className="client-list-title flex">
                {
                  client.list.map((item, index) => (
                    <div className={`client-list-title-item ${clientIndex === index ? "client-title-active" : null}`} key={index} onClick={() => toggleClient(index)}>
                      <span className="pointer">{item.title}</span>
                      <i className="client-list-title-line"></i>
                    </div>
                  ))
                }
              </div>
              {
                client.list.map((item, index) => (
                  clientIndex === index && <div className="client-list-swiper flex flex-Aconter" key={index}>
                    <div className='flex-center client-swiper-button-prev'>
                      <Icon icon="angle-left" className="swiper-btn-icon flex-center" />
                    </div>
                    <div className='client-swiper-container swiper-container'>
                      <div className='swiper-wrapper' >
                        {
                          item.imgs.map((ite, ind) => (
                            <img className='swiper-slide flex-col-center' src={ite} key={ind} />
                          ))
                        }
                      </div>
                      ))
                    </div>
                    <div className='flex-center client-swiper-button-next'>
                      <Icon icon="angle-right" className="swiper-btn-icon flex-center" />
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Merchant
