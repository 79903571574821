import { useEffect, useState } from 'react'

// Gatsby环境中window等变量只能在写在useEffect、componentDidMount中，否则会报错
export const useGetBusinessLinkByEnv = () => {
	const [businessLinkUrl, setBusinessLinkUrl] = useState('')
	useEffect(() => {
		const HOST = window.location.host
		let businessLinkUrl
		const envMap = {
			NDEV: 'www.ndev.imdada.cn',
			QA: 'www.qa.imdada.cn',
			ONLINE: 'www.imdada.cn',
		}
		switch (HOST) {
			case envMap.NDEV:
				businessLinkUrl = "https://bdms-new.ndev.imdada.cn";
				break;
			case envMap.QA:
				businessLinkUrl = "https://bdms.qa.imdada.cn";
				break;
			case envMap.ONLINE:
				businessLinkUrl = "https://bdms.imdada.cn";
				break;
			default:
				businessLinkUrl = "https://bdms.imdada.cn";
				break;
		}
		setBusinessLinkUrl(businessLinkUrl + '/app#/consultation?source=5')
	}, [])
	return businessLinkUrl
}
