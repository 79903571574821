import headerBg from '../images/merchant/head-bg.jpg'
import headerBgS from '../images/merchant/head-bg-s.jpg'
import headerIcon1 from '../images/merchant/head-icon1.png'
import headerIcon2 from '../images/merchant/head-icon2.png'
import headerIcon3 from '../images/merchant/head-icon3.png'
import introLeftImg from '../images/merchant/intro-left.png'
import introRightImg from '../images/merchant/intro-right.png'
import productBgImg from '../images/merchant/product-bg-ms.jpg'
import productBgImgS from '../images/merchant/product-bg-s-ms.jpg'
import productIcon1 from '../images/merchant/pro-icon1-ms.png'
import productIcon2 from '../images/merchant/pro-icon2-ms.png'
import productIcon3 from '../images/merchant/pro-icon3-ms.png'
import productIcon4 from '../images/merchant/pro-icon4-ms.png'
import advantageIcon1 from '../images/merchant/adva-icon1-ms.png'
import advantageIcon2 from '../images/merchant/adva-icon2-ms.png'
import advantageIcon3 from '../images/merchant/adva-icon3-ms.png'
import advantageIcon4 from '../images/merchant/adva-icon4-ms.png'
import advantageIcon5 from '../images/merchant/adva-icon5-ms.png'
import advantageIcon6 from '../images/merchant/adva-icon6-ms.png'
import advantageIcon7 from '../images/merchant/adva-icon7-ms.png'
import advantageIcon8 from '../images/merchant/adva-icon8-ms.png'
import advantageIcon9 from '../images/merchant/adva-icon9-ms.png'
import schemeBg0 from '../images/merchant/shangchao.jpg'
import schemeBg01 from '../images/merchant/canyin.jpg'
import schemeBg02 from '../images/merchant/yiyao.jpg'
import schemeBg03 from '../images/merchant/shengxian.jpg'
import schemeBg04 from '../images/merchant/bianli.jpg'
import schemeBg05 from '../images/merchant/chayin.jpg'
import schemeBg06 from '../images/merchant/xiandan.jpg'
import schemeBg07 from '../images/merchant/qipei.jpg'
import schemeBg08 from '../images/merchant/shuma.jpg'

import schemeBgS0 from '../images/merchant/shangchao-s.jpg'
import schemeBgS01 from '../images/merchant/canyin-s.jpg'
import schemeBgS02 from '../images/merchant/yiyao-s.jpg'
import schemeBgS03 from '../images/merchant/shengxian-s.jpg'
import schemeBgS04 from '../images/merchant/bianli-s.jpg'
import schemeBgS05 from '../images/merchant/chayin-s.jpg'
import schemeBgS06 from '../images/merchant/xiandan-s.jpg'
import schemeBgS07 from '../images/merchant/qipei-s.jpg'
import schemeBgS08 from '../images/merchant/shuma-s.jpg'

import schemeLeft0 from '../images/merchant/schemeLeft0.jpg'
import schemeLeft01 from '../images/merchant/schemeLeft01.png'
import schemeLeft02 from '../images/merchant/schemeLeft02.png'
import schemeLeft03 from '../images/merchant/schemeLeft03.png'
import schemeLeft04 from '../images/merchant/schemeLeft04.png'
import schemeLeft05 from '../images/merchant/schemeLeft05.png'
import schemeLeft06 from '../images/merchant/schemeLeft06.png'
import schemeLeft07 from '../images/merchant/schemeLeft07.png'
import schemeLeft08 from '../images/merchant/schemeLeft08.png'
import sckehu01 from '../images/merchant/sckehu01.png'
import sckehu02 from '../images/merchant/sckehu02.png'
import sckehu03 from '../images/merchant/sckehu03.png'
import sckehu04 from '../images/merchant/sckehu04.png'
import cykehu01 from '../images/merchant/cykehu01.jpg'
import cykehu02 from '../images/merchant/cykehu02.jpg'
import cykehu03 from '../images/merchant/cykehu03.jpg'
import cykehu04 from '../images/merchant/cykehu04.jpg'
import yykehu01 from '../images/merchant/yykehu01.jpg'
import yykehu02 from '../images/merchant/yykehu02.jpg'
import yykehu03 from '../images/merchant/yykehu03.jpg'
import yykehu04 from '../images/merchant/yykehu04.jpg'
import blkehu01 from '../images/merchant/blkehu01.jpg'
import caykehu02 from '../images/merchant/caykehu02.jpg'
import caykehu03 from '../images/merchant/caykehu03.jpg'
import xdkehu01 from '../images/merchant/xdkehu01.jpg'
import xdkehu02 from '../images/merchant/xdkehu02.jpg'
import xdkehu03 from '../images/merchant/xdkehu03.jpg'
import qpkehu01 from '../images/merchant/qpkehu01.jpg'
import smkehu01 from '../images/merchant/smkehu01.jpg'
import smkehu02 from '../images/merchant/smkehu02.jpg'
import shang01 from '../images/merchant/shang01.png'
import shang02 from '../images/merchant/shang02.png'
import shang03 from '../images/merchant/shang03.png'
import shang04 from '../images/merchant/shang04.png'
import shang05 from '../images/merchant/shang05.png'
import shang06 from '../images/merchant/shang06.png'
import can01 from '../images/merchant/can01.png'
import can02 from '../images/merchant/can02.png'
import can03 from '../images/merchant/can03.png'
import can04 from '../images/merchant/can04.png'
import can05 from '../images/merchant/can05.png'
import can06 from '../images/merchant/can06.png'
import can07 from '../images/merchant/can07.png'
import can08 from '../images/merchant/can08.png'
import yi01 from '../images/merchant/yi01.png'
import yi02 from '../images/merchant/yi02.png'
import yi03 from '../images/merchant/yi03.png'
import yi04 from '../images/merchant/yi04.png'
import yi05 from '../images/merchant/yi05.png'
import yi06 from '../images/merchant/yi06.png'
import yi07 from '../images/merchant/yi07.png'
import sheng01 from '../images/merchant/sheng01.png'
import sheng02 from '../images/merchant/sheng02.png'
import sheng03 from '../images/merchant/sheng03.png'
import sheng04 from '../images/merchant/sheng04.png'
import sheng05 from '../images/merchant/sheng05.png'
import sheng06 from '../images/merchant/sheng06.png'
import sheng07 from '../images/merchant/sheng07.png'
import shu01 from '../images/merchant/shu01.png'
import shu02 from '../images/merchant/shu02.png'
import shu03 from '../images/merchant/shu03.png'
import shu04 from '../images/merchant/shu04.png'
import shu05 from '../images/merchant/shu05.png'
import shu06 from '../images/merchant/shu06.png'
import clientBg from '../images/merchant/clientBg.jpg'



const data = {
  header: {
    headerBg,
    headerBgS,
    list: [
      {
        icon: headerIcon1,
        title: '智慧物流系统'
      },
      {
        icon: headerIcon2,
        title: '开放性平台'
      },
      {
        icon: headerIcon3,
        title: '定制化产品'
      }
    ]
  },
  introduce: {
    content: '达达秒送成立于2014年，成立伊始便聚焦于餐饮、商超即时配送领域， 后业务拓展至医药、茶饮、生鲜、蛋糕、鲜花、数码、汽配等全行业、全品类。达达秒送在以往配送经验基础上，经过长期实践和服务升级，推出四大连锁品牌服务产品：即刻达、全速达、全城送三种标准化配送服务，以及全心达定制化配送解决方案。全心达通过“驻店+众包”定制化混合运力模式、定制化服务体系、专业化运营团队、智能即时配送系统，为连锁品牌提供“全心服务，即时送达”的高效履约解决方案。',
    img: {
      leftImg: introLeftImg,
      rightImg: introRightImg,
    },
    list: [
      {
        data: '8',
        unit: '年+',
        explain: '配送经验'
      },
      {
        data: '1000万',
        unit: '+',
        explain: '日峰值订单  '
      },
      {
        data: '数十万',
        unit: '+',
        explain: '活跃骑士'
      },
      {
        data: '2700',
        unit: '+',
        explain: '业务累计覆盖县区市'
      },
    ]
  },
  product: {
    bgImg: productBgImg,
    bgImgS: productBgImgS,
    list: [
      {
        icon: productIcon1,
        title: '即刻达',
        text: '距离2公里内，采用优质专属驻店为主的运力配送服务模式，适合连锁餐饮、高端茶饮、医药等高品质需求客户'
      },
      {
        icon: productIcon2,
        title: '全速达 ',
        text: '距离5公里内，采用“驻店+众包”运力配送服务模式，适合商超、普通餐饮、便利、生鲜等高品质需求客户'
      },
      {
        icon: productIcon3,
        title: '全城送',
        text: '针对高客单、门店订单分散的商家和下沉市场特性，推出“全城送” 远距离配送服务，适合手机数码、鲜花蛋糕、医药等远距离配送的商户'
      },
      {
        icon: productIcon4,
        title: '定制化解决方案',
        text: '根据品牌特性、业务形态、配送场景推出定制化配送解决方案，提供高品质服务，帮助客户达到降本增效目标'
      }
    ]
  },
  advantage: {
    group: [
      {
        id: 1,
        title: '高效履约',
        list: [
          {
            icon: advantageIcon1,
            title: '定制化混合运力',
            text: '以大网运力、优质众包、品牌众包、驻店运力等多层混合运力，前置预测门店情况，精准匹配商家需求，有效应对削峰填谷，全力保障配送时效'
          },
          {
            icon: advantageIcon2,
            title: '智慧物流系统',
            text: '对订单进行智能分派、智能动态定价和全链路实时跟进，准确预判订单趋势，配送节点时效把控'
          },
          {
            icon: advantageIcon3,
            title: '精细化运营',
            text: ' “总部+城市站”联合运营，搭建严密的服务体系，实现一对一响应，保障大促稳定运营，有效应对削峰填谷'
          }
        ]
      },
      {
        id: 2,
        title: '增值服务',
        list: [
          {
            icon: advantageIcon4,
            title: '定制装备',
            text: '品牌定制联名配送装备，提供品牌专属服务，提升品牌形象'
          },
          {
            icon: advantageIcon5,
            title: '系统化培训',
            text: '系统化培训骑士，统一话术与服务标准，为消费者提供有温度的服务'
          },
          {
            icon: advantageIcon6,
            title: '品牌营销',
            text: '借助达达众包优势，满足商家大促、节日品牌宣传需求'
          }
        ]
      },
      {
        id: 3,
        title: '全链路',
        list: [
          {
            icon: advantageIcon7,
            title: '双平台能力',
            text: '基于达达集团“即时零售+即时物流”双平台的能力，为大型连锁商超提供“仓、拣、配”全链路数字化的解决方案'
          },
          {
            icon: advantageIcon8,
            title: '达达优拣',
            text: '由达达众包拣货员为零售门店提供拣货服务，完成全渠道订单拣货、商品打包、订单交付等工作，提升订单拣货效率'
          },
          {
            icon: advantageIcon9,
            title: '无人配送服务',
            text: '依托达达无人配送开放平台，打造商超即时配送场景下无人配送生态。在遭遇恶劣天气、业务高峰期、夜间配送等运力不稳定情况下，无人配送车可作为补充运力'
          }
        ]
      }
    ]
  },
  scheme: {
    list: [
      {
        icon: 'Da_shangchao',
        title: '商超',
        bgImg: schemeBg0,
        bgImgS: schemeBgS0,
        contentImg: schemeLeft0,
        feature: [
          '大促期间运力弹性要求高；',
          '货物品类多、体积重，配送需求复杂；',
          '配送流程长，并且拣货、取货交接环节时效有待提升。'
        ],
        project: [
          '预测大促单量，提前安排好运力，并通过网格化的运力保障配送；',
          '借助达达优拣，助力零售商实现拣货作业、拣货管理的数字化和产品化，提高拣货速度和效能；',
          '依据货物特性，匹配不同载具的冷冻车。'
        ],
        client: [sckehu01, sckehu02, sckehu03, sckehu04]
      },
      {
        icon: 'Da_canyin',
        title: '餐饮',
        bgImg: schemeBg01,
        bgImgS: schemeBgS01,
        contentImg: schemeLeft01,
        feature: [
          '时效要求高，注重服务体验；',
          '午晚高峰期间运力弹性要求高，恶劣天气运力不足；',
          '对品牌形象和配送装备有一定要求；',
          '配送距离有限制。'
        ],
        project: [
          '订单智能分派，规划最优路径，合并顺路订单，实现降本增效',
          '错峰调度运力，高峰期稳定的运力补给；',
          '根据天气情况，提前匹配骑士，竭力度过恶劣天气；',
          '针对特,殊品类，提供个性化配送方案。'
        ],
        client: [cykehu01, cykehu02, cykehu03, cykehu04]
      },
      {
        icon: 'Da_yiyao',
        title: '医药',
        bgImg: schemeBg02,
        bgImgS: schemeBgS02,
        contentImg: schemeLeft02,
        feature: [
          '7*24小时营业，需全天候运力在线，夜间运力需求大；',
          '配送时效要求高；',
          '配送覆盖范围广。',
        ],
        project: [
          '搭建夜间网格运力，及时响应品牌配送需求；',
          '配置呼叫系统，提醒骑士接单；',
          '夜间客服，辅助解决运力调配和处理客诉；',
          '针对特,采用多种混合运力模式，以更高效经济的配送方案，帮助品牌降本增效。'
        ],
        client: [yykehu01, yykehu02, yykehu03, yykehu04]
      },
      {
        icon: 'Da_shengxian',
        title: '生鲜',
        bgImg: schemeBg03,
        bgImgS: schemeBgS03,
        contentImg: schemeLeft03,
        feature: [
          '冻品、冷藏品配送要求高；',
          '保持商品鲜活，对时效要求非常高；',
          '货物不能挤压，需保持完好。',
        ],
        project: [
          '保温箱冷热分离，保持商品的完整性；',
          '依托智慧物流技术，提前规划最优配送路线，保证以最快的时效送达；',
          '统一培训骑士货品摆放准则，定期进行抽查、反馈。'
        ],
        client: []
      },
      {
        icon: 'Da_bianli',
        title: '便利',
        bgImg: schemeBg04,
        bgImgS: schemeBgS04,
        contentImg: schemeLeft04,
        feature: [
          '24小时营业，夜间运力弹性要求；',
          '多为2公里内的客户，时效要求高。'
        ],
        project: [
          '运力24小时在线，提供配送服务；',
          '打造夜间运力网格，化解单量密集区域的压力。'
        ],
        client: [blkehu01]
      },
      {
        icon: 'Da_chayin',
        title: '茶饮',
        bgImg: schemeBg05,
        bgImgS: schemeBgS05,
        contentImg: schemeLeft05,
        feature: [
          '商品易撒易漏，保鲜时间短；',
          '门店高峰期容易出现爆单现象。'
        ],
        project: [
          '餐箱内部卡槽分区，冷热订单可同时配送；',
          '通过定制化多层混合运力，满足削峰填谷需求，提升配送时效；',
          '借助达达众包优势，满足商家品牌宣传需求。'
        ],
        client: [ caykehu02, caykehu03]
      },
      {
        icon: 'Da_dangao',
        title: '鲜花蛋糕',
        bgImg: schemeBg06,
        bgImgS: schemeBgS06,
        contentImg: schemeLeft06,
        feature: [
          '易损坏，耐热性差，时效要求高；',
          '配送难度非常高，配送范围广。'
        ],
        project: [
          '专属蛋糕骑士，专属包装袋，专人专送，保证商品完好；',
          '针对不同配送距离，匹配不同的载具，保障时效性。'
        ],
        client: [xdkehu01, xdkehu02, xdkehu03]
      },
      {
        icon: 'Da_qipei',
        title: '汽配',
        bgImg: schemeBg07,
        bgImgS: schemeBgS07,
        contentImg: schemeLeft07,
        feature: [
          '门店分散，单量不集中；',
          '配送距离远，服务要求高；',
          '用户需求紧急，时效要求高。'
        ],
        project: [
          '挖掘品牌单量集中区域，拓展运力；',
          '可提供专人专送服务，提高配送速度和服务质量；',
          '根据需求匹配不同时效的载具。'
        ],
        client: [qpkehu01]
      },
      {
        icon: 'a-Da_qipeifuben',
        title: '3C数码',
        bgImg: schemeBg08,
        bgImgS: schemeBgS08,
        contentImg: schemeLeft08,
        feature: [
          '高价值订单，配送质量要求极高',
          '门店分散，配送距离远，单量不集中；',
          '用户需求紧急，时效要求高。'
        ],
        project: [
          '可提供专人专送服务，提高配送速度和服务质量；',
          '匹配“信用骑士”，保障货品安全；',
          '针对不同配送距离，匹配不同的载具，保障时效性。'
        ],
        client: [smkehu01, smkehu02]
      }
    ]
  },
  client: {
    bgImg: clientBg,
    list: [
      {
        title: '商超',
        imgs: [shang01, shang02, shang03, shang04, shang05, shang06]
      },
      {
        title: '餐饮',
        imgs: [can01, can02, can03, can04, can05, can06, can07, can08]
      },
      {
        title: '医药',
        imgs: [yi01, yi02, yi03, yi04, yi05, yi06, yi07]
      },
      {
        title: '生鲜',
        imgs: [sheng01, sheng02, sheng03, sheng04, sheng05, sheng06, sheng07]
      },
      {
        title: '数码',
        imgs: [shu01, shu02, shu03, shu04, shu05, shu06]
      }
    ]
  }
}

export default data
